<template>
  <b-card
    no-body
  >
    <!-- Sets/Milestones -->
    <div class="d-flex">
      <h4 class="mb-2 ml-2">
        {{ $t('SETS_MILESTONES') }}
      </h4>
      <div>
        <feather-icon
          v-if="canEditCompetition"
          icon="EditIcon"
          size="16"
          class="text-muted cursor-pointer ml-1 mr-1"
          @click="$router.push({ name: 'set-list-id', params: { id: encryptId }})"
        />
      </div>
    </div>
    <app-timeline class="mb-2 ml-3">
      <!-- SET REPORT 1-->
      <app-timeline-item
        v-for="(settime,index) in settimes"
        :id="settime.settime_id.toString()"
        :key="index"
        variant="primary"
        icon="ClockIcon"
      >

        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>{{ $t("SET") }} {{ index+1 }} : {{ formatDateTime(settime.settime) }}</h6>
        </div>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import {
  VBToggle, VBTooltip, BCard,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import { mixinDate } from '@/constants/mixinDate'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BCard,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  mixins: [mixinDate],
  props: {
    settimes: {
      type: Array,
      default: () => [],
      required: true,
    },
    isShowReport: {
      type: Boolean,
      default: () => true,
    },
    canEditCompetition: {
      type: Boolean,
      default: () => true,
    },
    encryptId: {
      type: String,
      default: () => 0,
      required: true,
    },
  },
}
</script>
