<template>
  <b-card
    v-if="competitionTasks"
    no-body
  >
    <!-- Header: Habit & Behaviour -->
    <div class="d-flex">

      <h4 class="mb-2 mr-1">
        {{ $t('HABIT_BEHAVIOUR') }}
      </h4>
      <feather-icon
        v-if="canEditCompetition"
        icon="EditIcon"
        size="16"
        class="text-muted cursor-pointer"
        @click="$router.push({ name: 'activity-list-id', params: { id: encryptId }})"
      />
    </div>
    <app-timeline
      v-for="(competitionTask,index) in competitionTasks"
      :id="competitionTask.task.taskID.toString()"
      :key="competitionTask.task.taskID"
    >
      <!-- Sub task-->
      <app-timeline-item
        :id="competitionTask.task.taskID.toString()"
        :key="index"
        variant="primary"
        icon="FileTextIcon"
      >

        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>{{ competitionTask.task.taskbeskrivning }} ( {{ competitionTask.task.points }}) </h6>
        </div>
        <p v-html="competitionTask.task.taskdescription" />
        <b-collapse
          :id="competitionTask.task.taskID.toString()"
          visible
        >
          <b-list-group
            flush
            class="mt-1"
          >
            <b-list-group-item
              v-for="(subtask,i) in competitionTask.task.task_subtasks"
              :id="subtask.subtask.subtaskID.toString()"
              :key="i"
              class="d-flex justify-content-between align-items-center bg-transparent"
            >
              <span> {{ subtask.subtask.subtaskstring }} ({{ subtask.subtask.subtaskpoint }}) <p v-html="subtask.subtask.subtaskHelpstring" /></span>
            </b-list-group-item>
          </b-list-group>
        </b-collapse>

      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BListGroup,
  BListGroupItem,
  VBToggle,
  VBTooltip,
  BCard,
  BCollapse,

} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import { mixinList } from '@/constants/mixinValidations'

export default {
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
    BCollapse,
    BListGroup,
    BListGroupItem,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  mixins: [mixinList],
  props: {
    competitionTasks: {
      type: Array,
      default: () => [],
      required: true,
    },
    canEditCompetition: {
      type: Boolean,
      default: () => true,
    },
    encryptId: {
      type: String,
      default: () => 0,
      required: true,
    },

  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style lang="scss" scoped>
.draggable-task-handle {
position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    cursor: move;
    .todo-task-list .todo-item:hover & {
    visibility: visible;
    }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
