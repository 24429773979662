import { ref } from '@vue/composition-api'
import store from '@/store'
import constants from '@/constants/static.json'
import { countingObject } from '@/constants/utils'

export default function useAnalytics() {
  const competition = ref({})

  const backgroundColor = color => `background-color:${color}`

  const fetchCompetition = id => {
    if (id) {
      store.dispatch('app-game/fetchAllGame', id).then(response => {
        if (response.data) {
          const { data } = response.data
          competition.value = data
          competition.value.ID = data.ID
          competition.value.name = data.c_string_swe
          competition.value.visionstatement = data.visionstatement
          competition.value.groups = data.groups
          // eslint-disable-next-line no-use-before-define
          const resuts = countingObject(data.groups)
          competition.usersCount = resuts.usersCount
          competition.groupCount = resuts.groupCount
        }
      }).catch(() => {
        // errorMessage('Error fetch competition list')
      })
    }
  }
  const sliceSortByValue = data => {
    const list = data.slice(0, constants.PAGES.LEADERBOARD_VIEW_PER_PAGE)
    list.sort((a, b) => b.points - a.points)
    // eslint-disable-next-line no-use-before-define
    return setPlace(list)
  }
  const setPlace = data => {
    const results = []
    data.forEach((item, index) => {
      // eslint-disable-next-line no-param-reassign
      item.place = index + 1
      results.push(item)
    })
    return results
  }

  return {
    sliceSortByValue,
    backgroundColor,
    fetchCompetition,
    competition,
  }
}
